import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, Form, Input, Row, Image, notification } from "antd";
import Label from "../../Label";
import useHttp from "../../../../hooks/use-http";
import { debounce } from "lodash"; // Add lodash for debouncing input
import CONSTANTS from "../../../../util/constant/CONSTANTS";

const photoGrapherUrl = "https://www.flickr.com/photos";
const apiKey = process.env.REACT_APP_FLICKER_API_KEY;

// Helper Functions
function isFlickrUrl(url) {
  try {
    const urlObj = new URL(url);
    return urlObj.hostname.endsWith("flickr.com");
  } catch (error) {
    console.error("Invalid URL:", error);
    return false;
  }
}

function extractPhotoId(url) {
  try {
    const urlObj = new URL(url);
    const pathSegments = urlObj.pathname.split("/");

    // Flickr photo URL format: /photos/[username]/[photo_id]/[optional_parameters]
    // Example: /photos/seamusmiley/14152340354/in/photolist-JRxodP-beXfJn-FshRKD

    // The photo ID is always the second segment after '/photos/[username]/'
    const photoId = pathSegments[3];

    return photoId || "";
  } catch (error) {
    console.error("Error extracting photo ID:", error);
    return null;
  }
}
async function getFlickrPhotoInfo(photoId, apiKey) {
  const apiUrl = "https://www.flickr.com/services/rest/";
  const params = new URLSearchParams({
    method: "flickr.photos.getInfo",
    api_key: apiKey,
    photo_id: photoId,
    format: "json",
    nojsoncallback: 1,
    extras: "license,owner_name,url_o,url_c,url_l,url_m,description",
  });

  try {
    const response = await fetch(`${apiUrl}?${params.toString()}`);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    if (data?.stat !== "ok") return null;

    const photoUrl = `https://live.staticflickr.com/${data.photo.server}/${data.photo.id}_${data.photo.secret}_b.jpg`;
    return { photoUrl, photographer: data?.photo?.owner };
  } catch (error) {
    console.error("Error fetching Flickr photo info:", error);
    return null;
  }
}

const LinkUploadPhoto = ({ beachData, refresher, masterRefresh }) => {
  const [form] = Form.useForm();
  const API = useHttp();
  const [photoUrl, setPhotoUrl] = useState(null);

  const clearFieldValue = () => {
    form.setFieldsValue({
      photographerName: null,
      photographerLink: null,
    });
  };

  const handleInputChange = useCallback(
    debounce(async (value) => {
      if (isFlickrUrl(value)) {
        clearFieldValue();
        const photoId = extractPhotoId(value);
        try {
          const fetchedData = await getFlickrPhotoInfo(photoId, apiKey);
          if (fetchedData) {
            setPhotoUrl(fetchedData.photoUrl);
            form.setFieldsValue({
              photographerName:
                fetchedData?.photographer?.realname ||
                fetchedData?.photographer?.username,
              photographerLink: `${photoGrapherUrl}/${
                fetchedData?.photographer?.path_alias ??
                fetchedData?.photographer?.nsid
              }`,
            });
          }
        } catch (error) {
          console.error("Failed to fetch photo info:", error);
        }
      } else {
        clearFieldValue();
        setPhotoUrl(null);
      }
    }, 500), // 500ms debounce
    []
  );

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        // Perform actions after validation
        const payload = { ...values, imageUrl: photoUrl || values.imageUrl };
        if (beachData?.id) {
          payload.AllBeachId = beachData?.id;
          payload.photoSource = "Google";
        }
        if (masterRefresh) {
          payload.isCoverPhoto = true;
        }
        // Send request
        API.sendRequest(CONSTANTS.API.BeachPhoto.AddPhoto, (res) => {
          refresher();
          form.resetFields();
        }, payload);
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

  return (
    <Form name="linkUploadPhoto" form={form}>
      <Row gutter={[16, 0]} className="items-center justify-between">
        <Col span={24} style={{ marginTop: "10px", marginBottom: "10px" }}>
          {/* Photo Link */}
          <Label required={true}>Photo Link</Label>
          <Form.Item
            name="imageUrl"
            rules={[
              { required: true, message: "Please enter a valid photo link" },
              { type: "url", message: "Photo link is not a valid URL" },
            ]}
          >
            <Input
              placeholder="Enter Photo Link"
              onChange={(e) => handleInputChange(e.target.value)}
            />
          </Form.Item>

          {/* Photographer Name */}
          <Label required={true}>Photographer Name</Label>
          <Form.Item
            name="photographerName"
            rules={[
              { required: true, message: "Please enter a photographer name" },
            ]}
          >
            <Input placeholder="Enter Photographer Name" />
          </Form.Item>

          {/* Photographer Link */}
          <Label required={true}>Photographer Link</Label>
          <Form.Item
            name="photographerLink"
            rules={[
              { required: true, message: "Please enter a valid photographer link" },
              { type: "url", message: "Photographer link is not a valid URL" },
            ]}
          >
            <Input placeholder="Enter Photographer Link" />
          </Form.Item>
        </Col>

        {/* Display Image */}
        <Col span={24}>
          <Form.Item shouldUpdate>
            {({ getFieldValue }) => {
              const imageUrl = getFieldValue("imageUrl");
              if (!imageUrl) return null;

              const image = isFlickrUrl(imageUrl) ? photoUrl : imageUrl;
              return (
                <Col xs={24} className="flex justify-center items-center">
                  <div className="aspect-video w-[400px] flex items-center justify-center">
                    {image && <Image src={image} />}
                  </div>
                </Col>
              );
            }}
          </Form.Item>
        </Col>
      </Row>

      {/* Submit Button */}
      <Row justify="end">
        <Button type="primary" loading={API.isLoading} onClick={handleFormSubmit}>
          Save
        </Button>
      </Row>
    </Form>
  );
};

export default LinkUploadPhoto;
