import React, { useEffect, useState } from "react";
import useHttp from "../../../../hooks/use-http";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { Button, Card, Col, Image, Input, Row, Spin } from "antd";
import { MdOutlineReadMore } from "react-icons/md";
import AddImageCard from "../../basic/Add-Image-Card";
import { getClearObject } from "../../../../util/functions";

const GooglePhoto = ({ beachData, refresher, masterRefresh }) => {
  const [serpPhoto, setSerpPhoto] = useState([]);
  const [title, setTitle] = useState("");
  const [resfresh, setRefresh] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
  });
  const [nextPageLink, setnextPageLink] = useState(null);

  const API = useHttp();
  useEffect(() => {
    const payload = getClearObject({
      ...pagination,
      search: title,
      nextPageLink,
    });
    title &&
      API.sendRequest(
        CONSTANTS.API.SERP,
        (res) => {
          console.log(res, res?.nextPageLink, !!res?.nextPageLink);
          if (!!res?.nextPageLink) {
            setnextPageLink(res?.nextPageLink);
          } else {
            setnextPageLink("");
          }

          if (pagination.page === 1) {
            setSerpPhoto(res?.data);
          } else {
            setSerpPhoto((prev) => [...prev, ...res?.data]);
          }
        },
        payload
      );
  }, [pagination.page, resfresh]);
  useEffect(() => {
    setTitle(beachData?.name);
    setRefresh((prev) => !prev);
  }, [beachData]);
  console.log(
    nextPageLink === null || (!!nextPageLink && pagination.page > 0),
    nextPageLink === null,
    !!nextPageLink && pagination.page > 0,
    nextPageLink,
    pagination.page
  );
  return (
    <div>
      <Row>
        <Col sm={24} lg={8} className="p-3">
          <Input.Search
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            onSearch={(v) => {
              setTitle(v);
              setPagination((prev) => {
                if (prev.page === 1) {
                  setRefresh((prevRef) => !prevRef);
                }
                return { ...prev, page: 1 };
              });
            }}
          />
        </Col>
      </Row>
      <Row className=" items-center justify-center">
        {serpPhoto?.map((img, i) => (
          <Col
            key={i}
            // style={{ height: "150px" }}
            sm={24}
            md={8}
            lg={6}
            className="p-3"
          >
            <AddImageCard
              original={img}
              img={{
                small: img?.thumbnail,
                large: img?.image,
                id: img?.id,
              }}
              loading={API.isLoading}
              onAdd={(checkboxValues, image) => {
                let description = image?.description;
                // if (descriptionElement) {
                //   description = descriptionElement.value;
                // }

                const payload = {
                  imageUrl: image?.image,
                  AllBeachId: beachData?.id,
                  licenseName: "CC BY 2.0",
                  licenseLink: "",
                  photoName: beachData?.name,
                  photoLink: image?.user?.image,
                  photographerName: image?.user?.name,
                  photographerLink: image?.user?.link,
                  photoSource: "Google Photos",
                  description,
                  ...checkboxValues,
                };
                if (masterRefresh) {
                  payload.isCoverPhoto = true;
                }
                API.sendRequest(
                  CONSTANTS.API.BeachPhoto.AddPhoto,
                  (res) => {
                    if (masterRefresh) {
                      refresher();
                    }
                  },
                  payload
                );
              }}
            />
          </Col>
        ))}
      </Row>
      <Row className=" items-center justify-center my-3">
        {API.isLoading ? (
          <Spin size="large" />
        ) : nextPageLink === null || (!!nextPageLink && pagination.page > 0) ? (
          <Button
            type="primary"
            onClick={() => {
              setPagination((prev) => ({ ...prev, page: prev?.page + 1 }));
            }}
          >
            Load More
          </Button>
        ) : (
          ""
        )}
      </Row>
    </div>
  );
};

export default GooglePhoto;
